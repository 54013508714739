import React, { Component } from "react";

class Logo extends Component {
  render() {
    const id = this.props.id || "";
    return (
      <svg
        id={id}
        className="logo"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 43.48 57.65"
      >
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#05a" />
            <stop offset="100%" stopColor="#0a5" />
          </linearGradient>
        </defs>
        <path
          className="cls-1"
          d="M6.23,13.28C2.35,16.31,.41,20.51,.41,25.87c0,3.61,.85,6.53,2.54,8.77,1.69,2.24,3.72,3.94,6.07,5.08,1.69,.83,3.75,1.65,6.15,2.48V9.32c-3.46,.7-6.45,2.01-8.94,3.96Z"
        />
        <path
          className="cls-1"
          d="M22.3,56.45c2.08,0,3.72-.48,4.92-1.44,1.2-.96,1.8-2.28,1.8-3.98,0-1.42-.48-2.6-1.44-3.53-.96-.93-2.13-1.69-3.53-2.3-1.39-.6-3.38-1.28-5.95-2.05-1.04-.32-2.02-.64-2.95-.96v7.69c.22,2.13,.96,3.76,2.21,4.88,1.26,1.12,2.9,1.68,4.92,1.68Z"
        />
        <path
          className="cls-1"
          d="M37.25,44.37c3.88-3.03,5.82-7.23,5.82-12.59,0-3.61-.85-6.53-2.54-8.77-1.69-2.24-3.72-3.94-6.07-5.08-1.69-.83-3.75-1.65-6.15-2.48V48.33c3.46-.7,6.45-2.01,8.94-3.96Z"
        />
        <path
          className="cls-1"
          d="M21.18,1.2c-2.08,0-3.72,.48-4.92,1.44-1.2,.96-1.8,2.28-1.8,3.98,0,1.42,.48,2.6,1.44,3.53,.96,.93,2.13,1.69,3.53,2.3,1.39,.6,3.38,1.28,5.95,2.05,1.04,.32,2.02,.64,2.95,.96V7.76c-.22-2.13-.96-3.76-2.21-4.88-1.26-1.12-2.9-1.68-4.92-1.68Z"
        />
      </svg>
    );
  }
}

export default Logo;
